/* eslint-disable */
<template>
  <div id="app">
    <div v-if="getGlobalLoader" class="main-wrapper main-loader-home">
      <div class="lds-sub-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <div
      v-if="
        typeof getUserData !== 'undefined' &&
          Object.keys(getUserData).length
      "
    >
      <div class="container-fluid zero-padding " v-if="getUserData.loggedIn">
          <div class="row">
            <!-- Header Starat -->
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <transition name="fade" mode="out-in">
                <main-header></main-header>
              </transition>
            </div>
            <!-- Content Start -->
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
              <div class="top-header">
                <!-- add Search -->
                <div class="search-area">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                </div>
                <div class="notifications">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
                </div>
                <div class="profile-area">
                  <img src="./../public/images/profile-icon.jpg">
                </div>
              </div>
              <div class="content-area-start">
                <transition name="fade" mode="out-in">
                  <div id="main" >
                    <vue-progress-bar></vue-progress-bar>
                    <router-view></router-view>
                  </div>
                </transition>
                <!-- Foort Start -->
                <div class="row">
                  <div class="col-sm-12">
                    <transition name="fade" mode="out-in">
                      <main-footer></main-footer>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div v-else>
        <router-view />
      </div>

    </div>
    <div v-else class="main-wraper-loader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import admin_header from "./components/header/index";
import admin_footer from "./components/footer/index";

export default {
  data() {
    return {};
  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  components: {
    "main-header": admin_header,
    "main-footer": admin_footer
  },
  computed: {
    getUserData() {
      return this.$store.state.auth;
    },
    getGlobalLoader() {
      return this.$store.state.general_load;
    }
  }
};
</script>
<style lang="scss" scoped>
.main-loader-home {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: wait;
    background: rgba(0,0,0,0.5);
}
</style>