var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"navigation-top"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_vm._m(0),_c('div',{staticClass:"side-nav",attrs:{"id":"side-menu"}},[_c('router-link',{attrs:{"to":{
          path: '/home'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/home-menu.png")}}),_vm._v(" Dashboard ")]),_c('router-link',{attrs:{"to":{
          path: '/petrol-station'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Petrol Station ")]),_c('router-link',{attrs:{"to":{
          path: '/shifts'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Shifts ")]),_c('router-link',{attrs:{"to":{
          path: '/shift-sales'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Shift Sale ")]),_c('router-link',{attrs:{"to":{
          path: '/orders'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Orders ")]),_c('router-link',{attrs:{"to":{
          path: '/tanks'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Tanks ")]),_c('router-link',{attrs:{"to":{
          path: '/stocks'
        },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Stocks ")]),_vm._m(1),_c('div',{staticClass:"collapse",attrs:{"id":"collapseExample"}},[_c('router-link',{attrs:{"to":{
            path: '/admin'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Admins ")]),_c('router-link',{attrs:{"to":{
            path: '/vendor'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Vendor ")]),_c('router-link',{attrs:{"to":{
            path: '/premium-clients'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Premium Clients ")]),_c('router-link',{attrs:{"to":{
            path: '/premium-clients-order'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Premium Clients Order ")]),_c('router-link',{attrs:{"to":{
            path: '/cashier'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Cashier ")])],1),_vm._m(2),_c('div',{staticClass:"collapse",attrs:{"id":"financeCollapse"}},[_c('router-link',{attrs:{"to":{
            path: '#'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Total Sales ")]),_c('router-link',{attrs:{"to":{
            path: '#'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Transactions ")]),_c('router-link',{attrs:{"to":{
            path: '/expenses'
          },"tag":"a"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Expense ")])],1),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logOutSection.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("../../../public/images/clerk.png")}}),_vm._v(" Logout ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-area"},[_c('img',{attrs:{"src":require("../../../public/images/pms-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"data-toggle":"collapse","href":"#collapseExample","role":"button","aria-expanded":"false","aria-controls":"collapseExample"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" User Management ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"data-toggle":"collapse","href":"#financeCollapse","role":"button","aria-expanded":"false","aria-controls":"financeCollapse"}},[_c('img',{attrs:{"src":require("../../../public/images/station.png")}}),_vm._v(" Finance ")])
}]

export { render, staticRenderFns }