var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.getGlobalLoader)?_c('div',{staticClass:"main-wrapper main-loader-home"},[_vm._m(0)]):_vm._e(),(
      typeof _vm.getUserData !== 'undefined' &&
        Object.keys(_vm.getUserData).length
    )?_c('div',[(_vm.getUserData.loggedIn)?_c('div',{staticClass:"container-fluid zero-padding"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-2 col-sm-12"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('main-header')],1)],1),_c('div',{staticClass:"col-xl-10 col-lg-10 col-md-10 col-sm-12"},[_c('div',{staticClass:"top-header"},[_c('div',{staticClass:"search-area"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"}})])]),_c('div',{staticClass:"notifications"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"}})])]),_vm._m(1)]),_c('div',{staticClass:"content-area-start"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{attrs:{"id":"main"}},[_c('vue-progress-bar'),_c('router-view')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('main-footer')],1)],1)])],1)])])]):_c('div',[_c('router-view')],1)]):_c('div',{staticClass:"main-wraper-loader"},[_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-area"},[_c('img',{attrs:{"src":require("./../public/images/profile-icon.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ripple"},[_c('div'),_c('div')])
}]

export { render, staticRenderFns }